import React, { Suspense } from 'react';
import { useState, useEffect } from 'react';

import {
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";

import { useAuthContext } from "../Contexts/AuthProvider";

import { useEnvironmentContext } from "../Contexts/EnvironmentProvider";

import { createTheme, ThemeProvider } from '@mui/material/styles';

import Header from "./PodcastHeader";
import Footer from "../Footer";
import { Box } from '@mui/material';

const PreviewPage = React.lazy(() => import("../Preview/PreviewPage"));
const ProfilePage = React.lazy(() => import("../Profile/ProfilePage"));
const PodcastHistoryPage = React.lazy(() => import("../Podcasts/PodcastHistory"));
const ChannelsPage = React.lazy(() => import("../Channel/Channels"));
const ChannelViewPage = React.lazy(() => import("../Channel/ChannelView"));

const LoginPanel = React.lazy(() => import("../Login/LoginPanel"));

const AboutPage = React.lazy(() => import("../Misc/About"));
const GuidePage = React.lazy(() => import("../Misc/Guide"));
const PrivacyPage = React.lazy(() => import("../Misc/Privacy"));
const TermsPage = React.lazy(() => import("../Misc/Terms"));
const ContactPage = React.lazy(() => import('../Misc/Contact'));
const FaqPage = React.lazy(() => import('../Misc/Faq'));
const GlossaryPage = React.lazy(() => import('../Misc/Glossary'));
const MembershipPage = React.lazy(() => import('../Misc/Membership'));
const PaymentPage = React.lazy(() => import('../Misc/Subscription'));

const GuestLanding = React.lazy(() => import('../GuestLanding'));
const AzureSignIn = React.lazy(() => import('../Misc/Azure/SignIn'));
const AzureSignOut = React.lazy(() => import('../Misc/Azure/SignOut'));

export default function PodcastSite() {
  const { token, user } = useAuthContext();

  const { siteVariant } = useEnvironmentContext();

  const [backgroundUrl, setBackgroundUrl] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const getRandomBackground = () => {
      const now = new Date();

      const hour = now.getHours();

      let timeString = '';
      let count = 1;
      let qualifier = '';

      let counts = [[3, 3], [3, 3], [2, 3]];

      let sizes = ['landscape', 'portrait'];

      const sizeIndex = window.innerWidth < window.innerHeight ? 1 : 0;

      if (hour >= 6 && hour < 12) { timeString = '1-morning'; count = counts[0][sizeIndex]; }
      else if (hour >= 12 && hour < 19) { timeString = '2-afternoon'; count = counts[1][sizeIndex]; }
      else { timeString = '3-night'; count = counts[2][sizeIndex]; }


      const index = Math.floor(Math.random() * count) + 1;

      return `/BG/${timeString}-${sizes[sizeIndex]}${qualifier}-${index}.jpg`;
    };

    setBackgroundUrl(getRandomBackground());
  }, []);

  const theme = createTheme({
    palette: {
      mode: 'dark',
      button: {
        main: 'rgba(180, 180, 180, 1)'
      },
      modal: {
        main: 'rgba(50, 50, 50, 1)'
      },
      theme: {
        main: "#FF01CD",
      }
    }
  });

  const navigateToRoot = () => {
    navigate('/', { replace: true });
  };

  const loggedIn = token !== null;

  const routesWithHeader = () => {
    return (
      <>
        <Route path="/guide" element={<GuidePage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/membership" element={<MembershipPage />} />
        <Route path="/subscription" element={<PaymentPage />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/glossary" element={<GlossaryPage />} />

        <Route path="/contact" element={<ContactPage />} />

        {!loggedIn &&
          <Route path="/channels" element={<GuestLanding pageContext="channels" />} />
        }
        <Route path="/channel/:channelId" element={<ChannelViewPage />} />
        <Route path="/history" element={loggedIn ? <PodcastHistoryPage /> : <GuestLanding pageContext="podcasts" />} />
      </>
    );
  }

  const routesWithoutHeader = () => {
    return (
      <>
        {loggedIn &&
          <Route path="/channels" element={<ChannelsPage />} />
        }
        <Route path="/members/:handle" element={<ProfilePage />} />
        <Route path="/profile/:userId" element={<ProfilePage />} />
        <Route path="/podcast-ep/:linkId" element={<PreviewPage />} />
        {(siteVariant === 'school' && !user) ?
          <Route path="/" element={<Navigate to="/about" replace={true} />} />
          :
          <Route path="/" element={<Navigate to="/channels" replace={true} />} />
        }
        <Route path="/auth/ms/login" element={<AzureSignIn />} />
        <Route path="/auth/ms/logout" element={<AzureSignOut />} />

      </>
    );
  }

  const overlayRoutes = () => {
    if (loggedIn) {
      return (
        <>
          <Route path="/login" element={<Navigate to="/" replace={true} />} />,
          <Route path="/register" element={<Navigate to="/" replace={true} />} />,
          <Route path="/resetpassword" element={<Navigate to="/" replace={true} />} />
        </>
      );
    }
    return (
      <>
        <Route path="/login" element={<LoginPanel initialState='login' onClose={navigateToRoot} onAuthenticate={navigateToRoot} />} />,
        <Route path="/register" element={<LoginPanel initialState='register' onClose={navigateToRoot} onAuthenticate={navigateToRoot} />} />,
        <Route path="/resetpassword" element={<LoginPanel initialState='resetPassword' onClose={navigateToRoot} onAuthenticate={navigateToRoot} />} />
      </>
    );
  }


  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          position: 'absolute', top: '0px', left: '0px', right: '0px',
          minHeight: '100dvh',
          display: 'flex',
          justifyContent: 'center'
        }}>
        <div style={{
          position: 'fixed', top: '0px', left: '0px', right: '0px', bottom: '0px',
          backgroundImage: `url(${backgroundUrl})`,
          backgroundSize: 'cover',
        }} />
        <Box sx={{
          width: { 'xs': '100%', 'md': '60%' },
          position: 'relative',
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
          display: 'flex'
        }}>
          <Suspense fallback={<div />}>
            <Routes>
              {routesWithoutHeader()}

              <Route path="*" element={
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      height: '100%'
                    }}>
                    <Header />

                    <Box sx={{ minHeight: { 'xs': 'calc(100% - 50px - 30px)', 'md': 'calc(100% - 90px - 30px)' } }}>
                      <Suspense fallback={<div />}>
                        <Routes>
                          {routesWithHeader()}
                        </Routes>
                      </Suspense>
                    </Box>
                    <Footer />
                  </Box>

                  <Suspense fallback={<div />}>
                    <Routes>
                      {overlayRoutes()}
                    </Routes>
                  </Suspense>
                </>
              } />
            </Routes>
          </Suspense>
        </Box>
      </div>
    </ThemeProvider>
  );
}