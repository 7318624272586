import { Link } from "react-router-dom";

import { useAuthContext } from "../Contexts/AuthProvider";

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from "@mui/material/Divider";
import IconButton from '@mui/material/IconButton';

import MenuIcon from '@mui/icons-material/Menu';

import UserAvatar from '../UserAvatar';
import NavMenu from "./NavMenu";

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useNavigateFrom from "../Hooks/useNavigateFrom";
import { useEnvironmentContext } from "../Contexts/EnvironmentProvider";
import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import User from "../interfaces/User";

const Header = () => {
  const navigate = useNavigateFrom();
  const { user } = useAuthContext();
  const { siteVariant } = useEnvironmentContext();

  const [school, setSchool] = useState<string | undefined>();

  const theme = useTheme();

  const buttonStyle: React.CSSProperties = { color: theme.palette.text.primary, textTransform: 'none', fontSize: '10pt', whiteSpace: 'nowrap', minWidth: 'auto', alignSelf: 'flex-start' };

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    let profileUrl = `/api/users/${user?.id}`;

    axios.get<User>(profileUrl)
      .then(res => {
        const userDetail = res.data;

        if (userDetail.classes && userDetail.classes.length > 0) {
          setSchool(userDetail.classes[0].school.name);
        }
      });
  }, []);

  const handleAvatarClick = () => {
    if (!user) return;

    if (user.handle)
      navigate(`/members/${user.handle}`);

    else navigate(`/profile/${user.id}`);
  }

  const getLogoSrc = () => {
//    if (siteVariant === 'school') {
//      return theme.palette.mode === 'dark' ? "/logo-school-white.png" : "/logo-school.png";
//    } else {
      return theme.palette.mode === 'dark' ? "/logo-white.png" : "/logo.png";
//    }
  }

  const getNavMenuItems = () => {
    let ret = [];

    if (user) {
      ret.push({ path: '/history', label: 'History' });
    }
    //    ret.push({ path: '/', label: 'Channels' });

    return ret;
  }

  //siteVariant === 'school' && school
  return (
    <Box sx={{
      flexShrink: '0',
      flexBasis: { 'xs': '50px', 'md': '90px' },
      height: { 'xs': '50px', 'md': '90px' },
      backgroundColor: theme.palette.background.default,
      position: 'sticky', top: '0px',
      zIndex: 2
    }}>
      <Stack
        sx={{ height: '100%' }}
        direction='row'
        alignItems='center'
      >
        {true &&
          <NavMenu iconElement={<MenuIcon />} sx={{ ml: '10px' }} mobile={isMobile} items={getNavMenuItems()} position='left' />
        }
        <div style={{ flex: '1' }} />
        {user ?
          <IconButton onClick={handleAvatarClick}>
            <UserAvatar user={user} sx={{
              width: { 'xs': '40px', 'md': '50px' },
              height: { 'xs': '40px', 'md': '50px' }
            }} />
          </IconButton>
          :
          <>
            <Button key={'login'} component={Link} to='/login' style={buttonStyle}>Sign In</Button>

            {siteVariant === 'default' &&
              <Button key={'register'} component={Link} to='/register' style={buttonStyle}>Join</Button>
            }
          </>
        }
      </Stack>

      <img style={isMobile ?
        { cursor: 'pointer', position: 'absolute', top: '5px', left: '50%', height: '40px', transform: 'translate(-50%, 0)' }
        : { cursor: 'pointer', position: 'absolute', top: '15px', left: '60px', height: '50px' }
      } onClick={() => navigate('/')} src={getLogoSrc()} />

      <Divider sx={{ background: '#fff' }} />
    </Box>
  );
}

export default Header;
