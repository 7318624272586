import React, { useState } from 'react'
import axios from 'axios'

import { useAuthContext } from "../Contexts/AuthProvider";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import useActivityLogger from '../Hooks/useActivityLogger';
import { Alert, CircularProgress, FormHelperText, Link, Typography, useMediaQuery, useTheme } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import AzureLogin from '../Misc/Azure/SignInButton';
import isEmail from 'validator/lib/isEmail';
import { useEnvironmentContext } from '../Contexts/EnvironmentProvider';

interface Props {
  onAuthenticated: () => void,
  onNavigateLogin: () => void,
}

const Register = ({ onAuthenticated, onNavigateLogin }: Props) => {
  const { updateCredential } = useAuthContext();

  const { siteVariant } = useEnvironmentContext();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const postActivity = useActivityLogger();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleFacebook = (next: () => void) => {
    setLoading(true);
    next();
  }

  const responseFacebook = async (response: any) => {
    console.log('response facebook', response);

    if (!response.id) {
      setError('Invalid response.');
      return;
    }
    if (!response.email) {
      setError('Invalid user email.');
      return;
    }

    try {
      const params = {
        id: response.id,
        email: response.email,
        name: response.name ?? `Facebook User #${response.id}`,
        picture: response.picture?.data?.url,
        accessToken: response.accessToken
      };

      setLoading(true);
      await axios.create().post('/api/users/facebook/create', params);
      const res = await axios.create().post('/api/users/facebook/authenticate', { id: params.id, email: params.email, accessToken: params.accessToken });

      //      setToken(res.data.token);
      await updateCredential(res.data.user, res.data.token);

      postActivity('register', {}, res.data.user.id);

      onAuthenticated();

    } catch (error: any) {
      console.log(error);

      setLoading(false);
      if (error.response && error.response.data) {
        setError(error.response.data);
      }
    }
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const validate = () => {
    if (!isEmail(username)) {
      setError('Email address is not valid.');
      return false;
    }
    if (password.length < 6) {
      setError('Your password needs to contain at least 6 characters.');
      return false;
    }

    return true;
  }

  const isEnabled = true;
  const handleSubmit = async (e: React.MouseEvent) => {
    e.preventDefault();

    if (!isEnabled) {
      onAuthenticated();
      return;
    }

    if (validate()) {
      try {
        setLoading(true);

        await axios.post('/api/users/create', { username: username, password: password });
        const res = await axios.create().post('/api/users/authenticate', { username: username, password: password });

        //setToken(res.data.token);
        await updateCredential(res.data.user, res.data.token);

        postActivity('register', {}, res.data.user.id);

        onAuthenticated();

      } catch (error: any) {
        console.log(error);
        setLoading(false);
        if (error.response && error.response.data) {
          setError(error.response.data);
        }
      }
    }
  }

  console.log(error);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}>

      {loading ?
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            py: '50px'
          }}
        >
          <CircularProgress />
        </Box>
        :
        <>
          {error &&
            <Alert severity="error" sx={{ mt: '20px' }}>{error}</Alert>
          }
          {!isEnabled ?

            <p style={{ textAlign: 'center', color: 'black', marginLeft: '20px', marginRight: '20px', marginTop: '20px', whiteSpace: 'pre-line' }}>
              The Vactors Club is currently in the testing phase.
              <br /><br />As such, you cannot create your own account just yet.
              <br /><br />All our members at this moment are by invitation only.
              <br /><br />However, if someone has shared a script with you, you can view it and listen to its recording without signing in.
            </p>

            :
            <>
              <TextField
                sx={{ m: '20px', mt: '40px' }}
                type='email'
                variant='outlined'
                value={username}
                onChange={e => setUsername(e.target.value)}
                label="Email"
              />

              <FormControl sx={{ m: '20px' }} variant="outlined">
                <InputLabel>Password</InputLabel>
                <OutlinedInput

                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
                <FormHelperText>at least 6 characters</FormHelperText>
              </FormControl>

              {isMobile ?
                <Typography textAlign='center' sx={{ marginLeft: '20px', marginRight: '20px' }}>
                  By clicking on Agree and Join,<br />you agree to The Vactors Club's<br /><Link href='/terms' underline="hover">Terms of Service</Link>.
                </Typography>
                :
                <Typography textAlign='center' style={{ marginLeft: '20px', marginRight: '20px' }}>
                  By clicking on Agree and Join, you agree to<br />The Vactors Club's <Link href='/terms' underline="hover">Terms of Service</Link>.
                </Typography>
              }
            </>
          }

          <Button sx={{
            backgroundColor: theme.palette.button.main,
            m: '20px', p: '15px', textTransform: 'none', fontSize: '20pt',
            borderRadius: '20px'
          }} variant="contained" onClick={handleSubmit}>{isEnabled ? 'Agree and Join' : 'Got it!'}</Button>

          {true &&
            <AzureLogin sx={{ alignSelf: 'center', mb: '20px' }} />
          }
          {false &&
            <FacebookLogin
              appId="626434279389803"
              fields="name,email,picture.type(large)"
              callback={responseFacebook}
              render={renderProps => {
                return (
                  <Button
                    sx={{
                      width: 'auto',
                      mx: '20px', p: '5px', backgroundColor: 'white', borderColor: 'black', textTransform: 'none', fontSize: '13pt',
                      borderRadius: '15px', color: 'black'
                    }}
                    variant="outlined"
                    onClick={() => handleFacebook(renderProps.onClick)}
                    startIcon={<FacebookIcon />}
                  >
                    Continue with Facebook
                  </Button>
                );
              }}
            />
          }


          <Typography textAlign='center' style={{ marginLeft: '20px', marginRight: '20px', marginTop: '20px' }}>
            I'm already a member of{isMobile ? <br /> : ' '}The Vactors Club.
          </Typography>

          <Button
            sx={{ fontWeight: 'bold', color: theme.palette.text.primary, textTransform: 'none', marginLeft: 'auto', marginRight: 'auto', marginTop: '0px', marginBottom: '10px', py: '0px' }}
            onClick={onNavigateLogin}>
            Sign In
          </Button>
        </>
      }
    </Box>
  );
}

export default Register;
