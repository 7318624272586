const getAltSite = () => {
  let altSite = window.location.origin;
  if(altSite.includes('.club')) {
    if(altSite.includes('podcast.')) {
      altSite = altSite.replace('podcast.', 'schools.');
    }  
    else {
      altSite = altSite.replace('schools.','podcast.');
    }  
  } else {
    if(altSite.includes('podcast.')) {
      altSite = altSite.replace('school-podcast.', 'schools.');
      altSite = altSite.replace('staging-podcast.', 'staging.');
      altSite = altSite.replace('podcast.', 'www.');
    }
  
    else {
      altSite = altSite.replace('schools.','school-podcast.');
      altSite = altSite.replace('staging.', 'staging-podcast.');
      altSite = altSite.replace('www.','podcast.');
    }  
  }
  return altSite;
}

const useGetAltSite = () => {
  return getAltSite;
}

const useNavigateAltSite = () => {
  return (to : string) => {
    window.location.replace(`${getAltSite()}${to}`);
  }
}

export { useGetAltSite, useNavigateAltSite }