import Box from "@mui/material/Box";

const Footer = () => {
  return (
    <Box sx={{height:'30px', px:'10px', fontSize:'9pt', display:'flex', alignItems:'center'}}>
      Copyright 2024. All Rights Reserved. Vactors Pte Ltd.
    </Box>
  );
}

export default Footer;
