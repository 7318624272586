import { Link } from "react-router-dom";

import { useAuthContext } from "../Contexts/AuthProvider";

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from "@mui/material/Divider";
import IconButton from '@mui/material/IconButton';

import MenuIcon from '@mui/icons-material/Menu';

import UserAvatar from '../UserAvatar';
import NavMenu from "./NavMenu";

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useNavigateFrom from "../Hooks/useNavigateFrom";
import { useEnvironmentContext } from "../Contexts/EnvironmentProvider";
import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import User from "../interfaces/User";

const Header = () => {
  const navigate = useNavigateFrom();
  const { user, hasPrivilege } = useAuthContext();
  const { siteVariant } = useEnvironmentContext();

  const [school, setSchool] = useState<string | undefined>();

  const theme = useTheme();

  const buttonStyle: React.CSSProperties = { color: theme.palette.text.primary, textTransform: 'none', fontSize: '10pt', whiteSpace: 'nowrap', minWidth: 'auto' };

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  
  useEffect(() => {
    let profileUrl = `/api/users/${user?.id}`;

    axios.get<User>(profileUrl)
      .then(res => {
        const userDetail = res.data;

        if (userDetail.classes && userDetail.classes.length > 0) {
          setSchool(userDetail.classes[0].school.name);
        }
      });
  }, []);

  const handleAvatarClick = () => {
    if (!user) return;

    if (user.handle)
      navigate(`/members/${user.handle}`);

    else navigate(`/profile/${user.id}`);
  }

  const getLogoSrc = () => {
//    if (siteVariant === 'school') {
//      return theme.palette.mode === 'dark' ? "/logo-school-white.png" : "/logo-school.png";
//    } else {
      return theme.palette.mode === 'dark' ? "/logo-white.png" : "/logo.png";
//    }
  }

  const getNavMenuItems = () => {
    let ret = [];

    if (hasPrivilege('template')) {
      ret.push({ path: '/templates', label: 'Writing Templates' });
    }

    if (hasPrivilege('audio_library')) {
      ret.push({ path: '/audio', label: 'Audio Library' });
    }
    return ret;
  }

  return (
    <Box sx={{
      flexShrink: '0',
      flexBasis: { 'xs': '50px', 'md': '100px' },
      height: { 'xs': '50px', 'md': '100px' },
      backgroundColor: theme.palette.background.default,
      position: 'sticky', top: '0px',
      zIndex: 2
    }}>
      <Stack
        sx={{ height: { 'xs': '100%', 'md': (user ? '100%' : 'auto') } }}
        direction='row'
        alignItems='center'
      >
        {isMobile &&
          <NavMenu iconElement={<MenuIcon />} mobile={true} items={getNavMenuItems()} />
        }
        <div style={{ flex: '1' }} />
        {user ?
          <IconButton onClick={handleAvatarClick}>
            <UserAvatar user={user} sx={{
              width: { 'xs': '40px', 'md': '80px' },
              height: { 'xs': '40px', 'md': '80px' }
            }} />
          </IconButton>
          :
          <>
            <Button key={'login'} component={Link} to='/login' style={buttonStyle}>Sign In</Button>

            {siteVariant === 'default' &&
              <>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Button key={'register'} component={Link} to='/register' style={buttonStyle}>Join</Button>
              </>
            }
          </>
        }
      </Stack>

      {siteVariant === 'school' && school ?
        <>
          <img style={isMobile ?
            { cursor:'pointer', position: 'absolute', top: '5px', left: '50%', height: '30px', transform: 'translate(-50%, 0)' }
            : { cursor:'pointer', position: 'absolute', top: '10px', left: '20px', height: '60px' }            
          } onClick={() => navigate('/')} src={ getLogoSrc() } />
          <Typography sx={isMobile ?
            { position: 'absolute', top: '35px', left: '50%', height: '40px', fontSize: '8pt', transform: 'translate(-50%, 0)' }
            : { position: 'absolute', top: '75px', left: '20px', fontSize: '10pt' }
          }>{`@${school}`}</Typography>
        </>
        :
        <img style={isMobile ?
          { cursor:'pointer', position: 'absolute', top: '5px', left: '50%', height: '40px', transform: 'translate(-50%, 0)' }
          : { cursor:'pointer', position: 'absolute', top: '10px', left: '20px', height: '80px' }
        } onClick={() => navigate('/')} src={ getLogoSrc() } />
      }
    </Box>
  );
}

export default Header;
